//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Stats',
  props: {
    stats: Object
  },
  methods: {
    percentStyle(material) {
      let width = 0;

      if (!material || (!material.passed && !material.total)) {
        width = 0;
      } else if (material.passed > material.total) {
        width = 100;
      } else {
        width = (material.passed / material.total) * 100;
      }

      return 'width: ' + width + '%';
    }
  }
}
