//
//
//
//
//
//
//

export default {
name: "SidebarSignIcon"
}
