//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from "vuex";
import Stats from "./Stats";
import Card from "@/components/sidebar/Card";
import PersonalCertificate from "@/components/sidebar/PersonalCertificate";
import SidebarSignIcon from "@/components/icons/SidebarSignIcon";
import $ from "jquery"

export default {
  name: "MobileSidebar",
  components: {Card, Stats, PersonalCertificate, SidebarSignIcon},
  computed: {
    ...mapState([
      'worker',
      'history',
      'stats',
      'email',
      'code',
      'emailError',
      'codeError',
      'personalCertificate',
      'personalCertificateLoading',
      'hasAvailableDsSlot',
    ]),
  },
  methods: {
    toggleCardProfile() {
      $('.card-profile-wrapper').slideToggle();
      $('.toggle-card-profile-icon').toggleClass('toggle-card-profile-icon-selected');
    }
  }
}
