//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from "jquery"

export default {
  name: 'Card',
  props: {
    worker: Object,
    history: Array,
    isMobile: Boolean,
  },
  methods: {
    showHistory() {
      $('#modalCadastre' + (this.isMobile ? '_mobile' : '')).modal('show')
    },
    hideHistory() {
      $('#modalCadastre' + (this.isMobile ? '_mobile' : '')).modal('hide')
    }
  }
}
